import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined'

import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import EuroIcon from '@mui/icons-material/Euro'

import FlagIcon from '@mui/icons-material/Flag'
import FlightIcon from '@mui/icons-material/Flight'
import HomeIcon from '@mui/icons-material/Home'
import HotelIcon from '@mui/icons-material/Hotel'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'

import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MoreIcon from '@mui/icons-material/More'

import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined'
import NearMeIcon from '@mui/icons-material/NearMe'
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined'
import PhoneIcon from '@mui/icons-material/Phone'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'
import TrainIcon from '@mui/icons-material/Train'
import WorkIcon from '@mui/icons-material/Work'

import iconBillomat from 'src/assets/images/apps/small/billomat.png'
import iconDebitoor from 'src/assets/images/apps/small/debitoor.png'
import iconLexoffice from 'src/assets/images/apps/small/lexoffice.png'
import iconNoPlugin from 'src/assets/images/apps/small/locked.png'
import iconSevdesk from 'src/assets/images/apps/small/sevdesk.png'
import iconDatev from 'src/assets/images/apps/small/datev.png'

import iconButtonLexoffice from 'src/assets/images/apps/button/lexware-office-button.png'
import iconButtonSevdesk from 'src/assets/images/apps/button/sevdesk-button.png'
import iconButtonDatev from 'src/assets/images/apps/button/datev-button.png'
import iconButtonBillomat from 'src/assets/images/apps/button/billomat-button.png'

import styles from './const.module.css'
import { ReactNode } from 'react'
import { PackageType } from 'src/flow-types/types'
import iconBasic from 'src/assets/images/packages/regular_month.png'
import iconRegularYear from 'src/assets/images/packages/regular_year.png'
import iconTeam from 'src/assets/images/packages/team.png'
import iconTeamAddUser from 'src/assets/images/packages/add_user.png'
import { ExpenseCategory } from 'src/flow-types/Trip'

export enum ConversionStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

export const TEXT_SNIPPETS = {
  VALIDATION_TARIFFS_FOR_EMPLOYER_NOTICE:
    'Individuelle Pauschalen können ausschließlich von Ihrem Arbeitgeber erstattet werden. Möchten Sie die Abrechnung Ihrer Reisekosten auf den',
  VALIDATION_TARIFFS_FOR_EMPLOYER_LINK: 'Arbeitgeber-Modus umstellen?',
  TARIFFS_DROPDOWN_EDIT: 'Eigene Pauschalen',
  TARIFFS_DROPDOWN_DEFAULT: 'Gesetzliche Pauschale',
}

export const EXPENSE_PAYMENT_TYPES = {
  PRIVATE: 'PRIVATE',
  COMPANY: 'COMPANY', //  Firma
  COMPANY_CREDIT_CARD: 'COMPANY_CREDIT_CARD', // Kreditkarte
  COMPANY_DEBIT_CARD: 'COMPANY_DEBIT_CARD', // EC-Karte
  COMPANY_CASH: 'COMPANY_CASH', // Bar
  COMPANY_TRANSFER: 'COMPANY_TRANSFER', // Überweisung
  COMPANY_DIRECT_DEBIT: 'COMPANY_DIRECT_DEBIT', // Lastschrift
}

export const EDIT_TYPE = {
  EXPENSE: 'EXPENSE',
  MEAL: 'MEAL',
  ROUTE: 'ROUTE',
}

export enum AI_FILE_PARSE {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  NONE = 'NONE',
}

/*export const PLUGINS = {
  LEXOFFICE: 'LEXOFFICE',
  DEBITOOR: 'DEBITOOR',
  SEVDESK: 'SEVDESK',
  BILLOMAT: 'BILLOMAT',
  NONE: 'NONE',
}*/

export enum PLUGINS {
  LEXOFFICE = 'LEXOFFICE',
  DEBITOOR = 'DEBITOOR',
  SEVDESK = 'SEVDESK',
  BILLOMAT = 'BILLOMAT',
  WISO = 'WISO',
  FASTBILL = 'FASTBILL',
  DATEV_FILES = 'DATEV_FILES',
  NONE = 'NONE',
}

export type PluginProp = {
  icon: string
  title: string
  buttonIcon: string
  color: string
}

type PluginProps = {
  [key in PLUGINS]: PluginProp
}

export const PLUGINS_PROPS: PluginProps = {
  [PLUGINS.LEXOFFICE]: {
    title: 'Lexware Office',
    icon: iconLexoffice,
    buttonIcon: iconButtonLexoffice,
    color: '#FF4554',
  },
  [PLUGINS.DEBITOOR]: {
    title: 'Debitoor',
    icon: iconDebitoor,
    buttonIcon: '',
    color: 'red',
  },
  [PLUGINS.SEVDESK]: {
    title: 'sevdesk',
    icon: iconSevdesk,
    buttonIcon: iconButtonSevdesk,
    color: '#F85238',
  },
  [PLUGINS.BILLOMAT]: {
    title: 'Billomat',
    icon: iconBillomat,
    buttonIcon: iconButtonBillomat,
    color: '#52bc7d',
  },
  [PLUGINS.NONE]: {
    title: '',
    icon: iconNoPlugin,
    buttonIcon: '',
    color: 'red',
  },
  [PLUGINS.WISO]: {
    title: '',
    icon: iconNoPlugin,
    buttonIcon: '',
    color: 'red',
  },
  [PLUGINS.FASTBILL]: {
    title: '',
    icon: iconNoPlugin,
    buttonIcon: '',
    color: 'red',
  },
  [PLUGINS.DATEV_FILES]: {
    title: 'DATEV',
    icon: iconDatev,
    buttonIcon: iconButtonDatev,
    color: '#00A992',
  },
}

export enum TRIP_EXPORT_STATUS {
  NONE = 'NONE',
  EXPORTING = 'EXPORTING',
  EXPORTED = 'EXPORTED',
  FAILED = 'FAILED',
  RETRY = 'RETRY',
}

export const TRIP_EXPORT_STATUS_PROPS = {
  [TRIP_EXPORT_STATUS.NONE]: {
    title: 'Nicht exportiert',
    icon: <CloudOffOutlinedIcon className={styles[TRIP_EXPORT_STATUS.NONE]} />,
  },
  [TRIP_EXPORT_STATUS.EXPORTING]: {
    title: 'Wird exportiert',
    icon: <SyncOutlinedIcon className={styles[TRIP_EXPORT_STATUS.EXPORTING]} />,
  },
  [TRIP_EXPORT_STATUS.EXPORTED]: {
    title: 'Exportiert',
    icon: (
      <CloudDoneOutlinedIcon className={styles[TRIP_EXPORT_STATUS.EXPORTED]} />
    ),
  },
  [TRIP_EXPORT_STATUS.FAILED]: {
    title: 'Export fehlgeschlagen',
    icon: (
      <CloudOffOutlinedIcon className={styles[TRIP_EXPORT_STATUS.FAILED]} />
    ),
  },
  [TRIP_EXPORT_STATUS.RETRY]: {
    title: 'Reise exportieren',
    icon: (
      <CloudUploadOutlinedIcon className={styles[TRIP_EXPORT_STATUS.RETRY]} />
    ),
  },
}

export enum TRIP_APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  UPDATING = 'UPDATING',
}

type TripApprovalStatusProps = {
  [key in TRIP_APPROVAL_STATUS]: {
    title: string
    icon: ReactNode
  }
}
export const TRIP_APPROVAL_STATUS_PROPS: TripApprovalStatusProps = {
  [TRIP_APPROVAL_STATUS.APPROVED]: {
    title: 'Genehmigt',
    icon: (
      <CheckCircleOutlineOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.APPROVED]}
      />
    ),
  },
  [TRIP_APPROVAL_STATUS.REJECTED]: {
    title: 'Zurückgewiesen',
    icon: (
      <NotInterestedOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.REJECTED]}
      />
    ),
  },
  [TRIP_APPROVAL_STATUS.DRAFT]: {
    title: 'Nicht eingereicht (Entwurf)',
    icon: (
      <InsertDriveFileOutlined className={styles[TRIP_APPROVAL_STATUS.DRAFT]} />
    ),
  },
  [TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL]: {
    title: 'Eingereicht',
    icon: (
      <MoveToInboxOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL]}
      />
    ),
  },
  [TRIP_APPROVAL_STATUS.UPDATING]: {
    title: 'Wird aktualisiert',
    icon: <SyncOutlinedIcon />,
  },
}

export enum PLUGIN_ERROR_CODE {
  UNAUTHORIZED = 'UNAUTHORIZED',
  DOWNLOAD_PDF_ERROR = 'DOWNLOAD_PDF_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNKNOWN_VALIDATION_ERROR = 'UNKNOWN_VALIDATION_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  MISSING_VOUCHER_ITEMS = 'MISSING_VOUCHER_ITEMS',
  POSITIVE_EXPENSE_COSTS_REQUIRED = 'POSITIVE_EXPENSE_COSTS_REQUIRED',
  POSITIVE_TOTAL_COSTS_REQUIRED = 'POSITIVE_TOTAL_COSTS_REQUIRED',
  MAX_FILE_SIZE_EXCEEDED = 'MAX_FILE_SIZE_EXCEEDED',
  BROKEN_VOUCHER = 'BROKEN_VOUCHER',
  SUPPLIER_NOT_FOUND = 'SUPPLIER_NOT_FOUND',
  LEXOFFICE_ACCOUNT_NUMBER_CONFLICT = 'LEXOFFICE_ACCOUNT_NUMBER_CONFLICT',
  INVALID_VAT_RATE = 'INVALID_VAT_RATE',
  SMALL_BUSINESS_RULES_VIOLATED = 'SMALL_BUSINESS_RULES_VIOLATED',
  GENERAL_DATEV_ERROR = 'GENERAL_DATEV_ERROR',
  MISSING_DATEV_DATE = 'MISSING_DATEV_DATE',
  INVALID_DATEV_TITLE = 'INVALID_DATEV_TITLE',
  DATEV_42_ONLY_FOR_EMPLOYEE = 'DATEV_42_ONLY_FOR_EMPLOYEE',
}

type PluginErrorCodeMessage = {
  [key in PLUGIN_ERROR_CODE]: {
    message: ReactNode
  }
}

export const PLUGIN_ERROR_CODE_MESSAGES: PluginErrorCodeMessage = {
  [PLUGIN_ERROR_CODE.UNAUTHORIZED]: {
    message: (
      <>
        Die Zugriffsberechtigung für die Partner-App ist abgelaufen. Bitte{' '}
        <a href='/settings/apps'>aktualisieren Sie die Verknüpfung</a>.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.DOWNLOAD_PDF_ERROR]: {
    message: (
      <>Fehler beim Erstellen des PDFs. Bitte versuchen Sie es erneut.</>
    ),
  },
  [PLUGIN_ERROR_CODE.INTERNAL_SERVER_ERROR]: {
    message: <>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</>,
  },
  [PLUGIN_ERROR_CODE.UNKNOWN_VALIDATION_ERROR]: {
    message: (
      <>
        Ein unbekannter Validierungsfehler von der Partner-App ist aufgetreten.
        Bitte wenden Sie sich an den Support.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.UNKNOWN_ERROR]: {
    message: (
      <>
        Ein unbekannter Fehler beim Exportieren ist aufgetreten. Bitte wenden
        Sie sich an den Support.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.MISSING_VOUCHER_ITEMS]: {
    message: (
      <>Abrechnungen ohne Posten werden von der Partner-App nicht akzeptiert.</>
    ),
  },
  [PLUGIN_ERROR_CODE.POSITIVE_EXPENSE_COSTS_REQUIRED]: {
    message: (
      <>
        Abrechnungen mit negativen Kosten werden von der Partner-App nicht
        akzeptiert.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.POSITIVE_TOTAL_COSTS_REQUIRED]: {
    message: (
      <>
        Abrechnungen mit negativen Gesamtkosten werden von der Partner-App nicht
        akzeptiert.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.MAX_FILE_SIZE_EXCEEDED]: {
    message: (
      <>
        Die Dateigröße überschreitet die zulässige Maximalgröße der Partner-App.
        Bitte verkleinern Sie die hochgeladenen Belegdateien.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.SUPPLIER_NOT_FOUND]: {
    message: (
      <>
        Es wurde kein Lieferant ausgewählt. Bitte tragen Sie einen{' '}
        <a href='/settings/apps'>Standardlieferanten in den Einstellungen</a>{' '}
        ein.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.LEXOFFICE_ACCOUNT_NUMBER_CONFLICT]: {
    message: (
      <>
        Es scheint einen Konflikt mit den Kundennummernkreisen Ihrer
        lexoffice-Kontakte zu geben. Überprüfen Sie Ihre{' '}
        <a href='https://app.lexoffice.de/settings/#/number-ranges'>
          Einstellungen
        </a>{' '}
        oder kontaktieren Sie den lexoffice-Support.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.INVALID_VAT_RATE]: {
    message: (
      <>
        Die Abrechnung wurde aufgrund fehlerhafter MwSt.-Angaben abgelehnt.
        Bitte überprüfen Sie Ihre eingetragenen MwSt.-Sätze.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.BROKEN_VOUCHER]: {
    message: (
      <>
        Der Export ist unvollständig, da das PDF der Abrechnung nicht übertragen
        wurde. Bitte löschen Sie die fehlerhafte Abrechnung in der Partner-App
        und prüfen Sie ob Ihre Belege noch komprimiert werden können bevor Sie
        den Export erneut durchführen.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.MISSING_DATEV_DATE]: {
    message: (
      <>Fehlende Datumsangabe. Bitte vervollständigen Sie den Reiseverlauf.</>
    ),
  },
  [PLUGIN_ERROR_CODE.SMALL_BUSINESS_RULES_VIOLATED]: {
    message: (
      <>
        Die Abrechnung wurde aufgrund der Kleinunternehmerregelung abgelehnt.
        Bitte stellen Sie sicher, dass Sie in Ihren{' '}
        <a href='/settings/apps'>Einstellungen</a> die Kleinunternehmerregelung
        aktiviert haben.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.INVALID_DATEV_TITLE]: {
    message: (
      <>
        DATEV akzeptiert Abrechnungstitel nur bis zu einer Länge von 30 Zeichen.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.GENERAL_DATEV_ERROR]: {
    message: (
      <>
        Die Abrechnung enthält nicht zulässige Einträge für DATEV. Falls Sie die
        Ursache nicht selbst beheben können, kontaktieren Sie bitte den Support.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.DATEV_42_ONLY_FOR_EMPLOYEE]: {
    message: (
      <>
        Der DATEV-Kontenrahmen SKR 42 ist nicht für die eigene Steuererklärung
        vorgesehen. Bitte ändern Sie Ihre Reise-Einstellung auf "Für
        Arbeitgeber"
      </>
    ),
  },
}

export const EXPENSE_PAYMENT_OPTIONS_COMPENSATION = [
  {
    title: 'Firma',
    value: EXPENSE_PAYMENT_TYPES.COMPANY,
    isSplitter: true,
    splitterTitle: 'Von Firma gezahlt',
  },
  {
    title: 'Firma - Kreditkarte',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_CREDIT_CARD,
  },
  {
    title: 'Firma - EC-Karte',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_DEBIT_CARD,
  },
  {
    title: 'Firma - Barzahlung',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_CASH,
  },
  {
    title: 'Firma - Überweisung',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_TRANSFER,
  },
  {
    title: 'Firma - Lastschrift',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_DIRECT_DEBIT,
  },
]

export const EXPENSE_PAYMENT_OPTIONS = [
  {
    title: 'Privat Auslage',
    value: EXPENSE_PAYMENT_TYPES.PRIVATE,
    isSplitter: true,
    splitterTitle: 'Privat gezahlt',
  },
  ...EXPENSE_PAYMENT_OPTIONS_COMPENSATION,
]

export const ROUTE_ITEM_TYPES = {
  start: {
    icon: <HomeIcon />,
    title: 'Reisebeginn',
    dateLabel: 'Reisebeginn',
    datePlaceholder: 'Abfahrtsdatum',
  },
  lap: {
    icon: <LocationOnIcon />,
    title: 'Etappe',
    dateLabel: 'Ankunft',
    datePlaceholder: 'Ankunftsdatum',
  },
  end: {
    icon: <FlagIcon />,
    title: 'Reiseende',
    dateLabel: 'Reiseende',
    datePlaceholder: 'Ankunftsdatum',
  },
  pause: {
    icon: <HourglassFullIcon />,
    title: 'Unterbrechung',
    dateLabel: '',
    datePlaceholder: '',
  },
  destination: {
    icon: <LocationOnIcon />,
    title: 'Reiseziel',
    dateLabel: 'Ankunft Reiseziel',
    datePlaceholder: 'Ankunftsdatum (optional)',
  },
}

export enum EXPENSE_TYPE {
  PRIVATE_RIDE = 'PRIVATE_RIDE',
  DETAILED_ACCOMODATION = 'DETAILED_ACCOMODATION',
  FLAT_ACCOMODATION = 'FLAT_ACCOMODATION',
  FUEL = 'FUEL',
  ADD_RIDE_COSTS = 'ADD_RIDE_COSTS',
  PARKING = 'PARKING',
  SERVICE = 'SERVICE',
  CATERING_OTHER = 'CATERING_OTHER',
  LUGGAGE = 'LUGGAGE',
  COMMUNICATION = 'COMMUNICATION',
  EXTRA_OTHER = 'EXTRA_OTHER',
  TRAIN = 'TRAIN',
  PLANE = 'PLANE',
  TAXI = 'TAXI',
  RENTAL = 'RENTAL',
  RIDE_OTHER = 'RIDE_OTHER',
  REFUND = 'REFUND',
  PREPAYMENT = 'PREPAYMENT',
  COMPENSATION_OTHER = 'COMPENSATION_OTHER',
  ENTRY_VISA_FEES = 'ENTRY_VISA_FEES',
  TOLL = 'TOLL',
  LOCAL_PUBLIC_TRANSPORT = 'LOCAL_PUBLIC_TRANSPORT',
}

type ExpenseType = {
  [key in EXPENSE_TYPE]: {
    icon: ReactNode
    title: string
  }
}

export const EXPENSE_TYPES: ExpenseType = {
  [EXPENSE_TYPE.PRIVATE_RIDE]: {
    icon: <NearMeIcon />,
    title: 'Privatfahrzeug',
  },
  [EXPENSE_TYPE.DETAILED_ACCOMODATION]: {
    icon: <HotelIcon />,
    title: 'Übernachtung',
  },
  [EXPENSE_TYPE.FLAT_ACCOMODATION]: {
    icon: <HotelIcon />,
    title: 'Übernachtungspauschale',
  },
  [EXPENSE_TYPE.FUEL]: {
    icon: <LocalGasStationIcon />,
    title: 'Tankstelle',
  },
  [EXPENSE_TYPE.ADD_RIDE_COSTS]: {
    icon: <EuroIcon />,
    title: 'Fahrtnebenkosten',
  },
  [EXPENSE_TYPE.PARKING]: {
    icon: <LocalParkingIcon />,
    title: 'Parkgebühren',
  },
  [EXPENSE_TYPE.SERVICE]: {
    icon: <RestaurantMenuIcon />,
    title: 'Bewirtung',
  },
  [EXPENSE_TYPE.CATERING_OTHER]: {
    icon: <RestaurantMenuIcon />,
    title: 'Verpflegungskosten - Sonstige',
  },
  [EXPENSE_TYPE.LUGGAGE]: {
    icon: <WorkIcon />,
    title: 'Gepäck',
  },
  [EXPENSE_TYPE.COMMUNICATION]: {
    icon: <PhoneIcon />,
    title: 'Kommunikation',
  },
  [EXPENSE_TYPE.EXTRA_OTHER]: {
    icon: <MoreIcon />,
    title: 'Reisenebenkosten - Sonstige ',
  },
  [EXPENSE_TYPE.TRAIN]: {
    icon: <TrainIcon />,
    title: 'Bahnticket',
  },
  [EXPENSE_TYPE.PLANE]: {
    icon: <FlightIcon />,
    title: 'Flugticket',
  },
  [EXPENSE_TYPE.TAXI]: {
    icon: <LocalTaxiIcon />,
    title: 'Fahrdienst',
  },
  [EXPENSE_TYPE.RENTAL]: {
    icon: <DirectionsCarIcon />,
    title: 'Mietwagen',
  },
  [EXPENSE_TYPE.LOCAL_PUBLIC_TRANSPORT]: {
    icon: <TrainIcon />,
    title: 'Öffentlicher Nahverkehr',
  },
  [EXPENSE_TYPE.RIDE_OTHER]: {
    icon: <MoreIcon />,
    title: 'Sonstige Verkehrsmittel',
  },
  [EXPENSE_TYPE.REFUND]: {
    icon: <CreditCardIcon />,
    title: 'Erstattung',
  },
  [EXPENSE_TYPE.PREPAYMENT]: {
    icon: <CreditCardIcon />,
    title: 'Vorauszahlung',
  },
  [EXPENSE_TYPE.COMPENSATION_OTHER]: {
    icon: <MoreIcon />,
    title: 'Sonstige Zahlungen',
  },
  [EXPENSE_TYPE.ENTRY_VISA_FEES]: {
    icon: <MoreIcon />,
    title: 'Einreise Gebühren',
  },
  [EXPENSE_TYPE.TOLL]: {
    icon: <MoreIcon />,
    title: 'Mautgebühren',
  },
}

type ExpenseCategoriesItem = {
  [key in ExpenseCategory]: {
    title: string
    types: EXPENSE_TYPE[]
  }
}

export const ExpenseCategories: ExpenseCategoriesItem = {
  [ExpenseCategory.CATERING]: {
    title: 'Verpflegungskosten',
    types: [EXPENSE_TYPE.SERVICE, EXPENSE_TYPE.CATERING_OTHER],
  },
  [ExpenseCategory.ACCOMODATION]: {
    title: 'Übernachtungskosten',
    types: [EXPENSE_TYPE.DETAILED_ACCOMODATION, EXPENSE_TYPE.FLAT_ACCOMODATION],
  },
  [ExpenseCategory.RIDE]: {
    title: 'Fahrtkosten',
    types: [
      EXPENSE_TYPE.PRIVATE_RIDE,
      EXPENSE_TYPE.LOCAL_PUBLIC_TRANSPORT,
      EXPENSE_TYPE.TRAIN,
      EXPENSE_TYPE.PLANE,
      EXPENSE_TYPE.TAXI,
      EXPENSE_TYPE.RENTAL,
      EXPENSE_TYPE.RIDE_OTHER,
    ],
  },
  [ExpenseCategory.EXTRA]: {
    title: 'Reisenebenkosten',
    types: [
      EXPENSE_TYPE.FUEL,
      EXPENSE_TYPE.ADD_RIDE_COSTS,
      EXPENSE_TYPE.PARKING,
      EXPENSE_TYPE.TOLL,
      EXPENSE_TYPE.ENTRY_VISA_FEES,
      EXPENSE_TYPE.COMMUNICATION,
      EXPENSE_TYPE.LUGGAGE,
      EXPENSE_TYPE.EXTRA_OTHER,
    ],
  },

  [ExpenseCategory.COMPENSATION]: {
    title: 'Auszahlungen',
    types: [
      EXPENSE_TYPE.PREPAYMENT,
      EXPENSE_TYPE.REFUND,
      EXPENSE_TYPE.COMPENSATION_OTHER,
    ],
  },
}

export const PACKAGES = {
  [PackageType.BASIC]: {
    image: iconBasic,
    features: [
      'Reisekostenabrechnung fürs In- und Ausland',
      'Gesetzliche Pauschalen',
      'Etappenreisen',
      'Kartendienst',
      'PDF Abrechnungen',
      'Filtern & Gruppieren',
      'Download einzeln oder gruppiert',
    ],
  },
  [PackageType.REGULAR]: {
    image: iconRegularYear,
    features: [
      'Alle Funktionen aus BASIS',
      'Beleg-Upload',
      'Eigenes Layout & Logo für Abrechnungen',
      'Partner Schnittstellen',
      'Vorauszahlungen',
      'Individuelle Pauschalen',
      'Währungsrechner',
      'CSV Download',
    ],
  },
  [PackageType.TEAM]: {
    image: iconTeam,
    features: [
      'Alle Funktionen aus PLUS',
      'Genehmigungsprozess',
      'Userverwaltung',
      'Userstatistik',
    ],
  },
  [PackageType.TEAM_USER]: {
    image: iconTeamAddUser,
    features: ['Zusätzliche Mitglieder Lizenzen für Ihr Team Tarif'],
  },
}
